.Cursor {
  cursor: pointer;
}

.mui-card {
  border-radius: 16px !important;
  box-shadow: 0 4px 11px rgba(194, 209, 217, 0.46) !important;
}

.content-image {
  width: 350px;
  display: block;
  margin: auto;
  border: 2px solid #dfe3e7;
  border-radius: 8px;
}

.formulario > div {
  margin-bottom: 20px;
}

.formulario label {
  display: block;
  font-weight: 600;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  margin-bottom: 5px;
  color: #404a56;
}
.Drawer {
  margin-left: 74px;
  padding: 3%;
}

.formulario .error {
  color: #b00020;
  font-size: 12px;
  margin-left: 16px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.head-login-container {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 200px;
  background: linear-gradient(4.38deg, #fabe00 5.73%, #fabe00 52.18%, #fbcc34 93.05%);
  border-radius: 0 0 30px 30px;
}
.MuiCircularProgress-svg {
  color: #fabe00;
}
.head-login-container img {
  width: 224px;
}

.body-login-container {
  position: sticky;
  z-index: 0;
  margin-top: 155px !important;
  height: 560px;
  border-radius: 16px;
  box-shadow: 0px 4px 11px rgba(194, 209, 217, 0.46);
  background: #ffffff;
  padding: 0px 50px;
  width: 424px;
}

.body-login-container .footer {
  margin-top: 30px;
  margin-bottom: 40px;
}

.body-login-container .text {
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #4c5866;
}

.footer-login-container {
  width: 98%;
  height: 72px;
  background: #f8f9fb;
  position: fixed;
  bottom: 0px;
  left: 0px;
}

.footer-login-container .text {
  font-family: Roboto, sans-serif;
  font-style: light;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #4c5866;
}

.formulario .title {
  display: flex;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: #404a56;
  padding: 50px 0;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.swal-button-contained {
  width: 118px;
  height: 40px;
  background: #ffc10e !important;
  color: #343c46 !important;
  border-radius: 4px !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
}

.swal-button-outlined {
  width: 118px;
  height: 40px;
  background: #ffffff !important;
  color: #343c46 !important;
  border: 1px solid #ffc10e !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
}

.swal-title {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px !important;
  color: #404a56 !important;
  text-align: start !important;
  margin: 0 0.4em !important;
}

.swal-htmlContainer {
  font-style: normal;
  font-weight: normal !important;
  font-size: 16px !important;
  color: #404a56 !important;
  width: 532px !important;
  height: 367px !important;
  margin: 0px !important;
}

.swal-container {
  max-width: 532px !important;
  max-height: 367px;
  width: 100% !important;
  height: 100%;
}

.link-none {
  text-decoration: none !important;
}

.mui-verticalTab {
  border-bottom: 1;
  border-color: "divider";
}

.iFrame {
  border: none !important;
}

.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: auto;
  background-color: #fff;
  box-shadow: 0px 16px 24px rgba(52, 60, 70, 0.14), 0px 6px 30px rgba(52, 60, 70, 0.12),
    0px 8px 10px rgba(52, 60, 70, 0.2);
  border-radius: 8px;
}
.modal-box .icon-close-modal {
  position: absolute;
  top: 13.75px;
  right: 22px;
  font-size: 20px;
  cursor: pointer;
}

.modal-box .input-copy {
  border: 1px solid #ccc;
  width: 80%;
  border-radius: 8px 0 0 8px;
  padding: 1px 4px;
  color: #004fb6;
}

.modal-box .button-copy {
  border: 1px solid #bfc7d0;
  padding: 10px;
  width: 15%;
  background: #004fb6;
  color: #fff;
  border-radius: 0 8px 8px 0;
}

.action-button {
  font-size: 16px !important;
}

.action-button-outlined {
  font-size: 16px !important;
}

.action-button:hover {
  background-color: #ffd350 !important;
}

.action-button:active {
  background-color: #ffd350 !important;
}

.action-button-outlined:hover {
  background-color: #e7e7e7 !important;
  border: 1px solid #ffc10e !important;
}

.action-button-outlined:active {
  background-color: #e7e7e7 !important;
  border: 1px solid #ffc10e !important;
}

.swal-button-outlined:hover {
  background-color: #e7e7e7 !important;
  border: 1px solid #ffc10e !important;
}

.swal-button-outlined:active {
  background-color: #e7e7e7 !important;
  border: 1px solid #ffc10e !important;
}

.swal-button-contained:hover {
  background-color: #ffd350 !important;
}

.swal-button-contained:active {
  background-color: #ffd350 !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background-image: url("data:image/svg+xml, %3Csvg%20width%3D%2218%22%20height%3D%2220%22%20viewBox%3D%220%200%2018%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M14%2011H9V16H14V11ZM13%200V2H5V0H3V2H2C0.89%202%200.00999999%202.9%200.00999999%204L0%2018C0%2019.1%200.89%2020%202%2020H16C17.1%2020%2018%2019.1%2018%2018V4C18%202.9%2017.1%202%2016%202H15V0H13ZM16%2018H2V7H16V18Z%22%20fill%3D%22%23404A56%22%2F%3E%0A%3C%2Fsvg%3E");
  height: 20px;
  width: 20px;
  padding-top: 0;
}
.action-button-secondary:hover {
  background-color: #ffd350 !important;
}

.action-button-secondary:active {
  background-color: #ffd350 !important;
}

.action-button-outlined-secondary:hover {
  background-color: #e7e7e7 !important;
  border: 1px solid #ffc10e !important;
}

.action-button-outlined-secondary:active {
  background-color: #e7e7e7 !important;
  border: 1px solid #ffc10e !important;
}

.swal2-actions {
  display: block;
  text-align: center !important;
  width: 100% !important;
}

.swal-actions {
  justify-content: center !important;
  width: 490px !important;
  margin-top: 42px !important;
  margin-bottom: 42px !important;
}

.swal-htmlContainer-with-swal-actions {
  font-style: normal;
  font-weight: normal !important;
  font-size: 16px !important;
  color: #404a56 !important;
  width: 500px !important;
  padding-left: 36px !important;
  height: 267px !important;
  margin: 0px !important;
}

.swal2-modal {
  height: 362px !important;
}

.no-information-message {
  text-align: center;
}

/* PRIVACY */
.head-privacy-container {
  width: 100%;
}

.img-tigo-privacy {
  width: 12%;
  position: absolute;
  z-index: 1;
  top: 15px;
}

.privacy-card {
  position: absolute;
  z-index: 1;
  overflow-y: auto;
  width: 80%;
  height: 60vh;
  top: 10px;
  background: #fff;
  box-shadow: 0px 10px 38px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  padding: 80px;
}

.footer-privacy-container {
  width: 98%;
  height: 72px;
  background: #00377d;
  position: fixed;
  bottom: 0px;
  left: 0px;
}

@media screen and (max-width: 1500px) {
  .privacy-card {
    height: 46vh;
  }
  .img-tigo-privacy {
    width: 10%;
  }
  .chart-labels-controls {
    font-size: 12px !important;
    margin-left: 0 !important;
  }

  .from-control-switch {
    width: 100px;
  }
  .date-control {
    margin-left: 5px !important;
  }
  .date-control input {
    max-width: 120px;
    font-size: 14px;
  }
  .date-container {
    margin: 0 !important;
  }
  .control-btn button {
    min-width: 100px !important;
    font-size: 14px !important;
    width: 100px !important;
  }
}

@media screen and (max-width: 1024px) {
  .privacy-card {
    height: 40vh;
  }
  .img-tigo-privacy {
    width: 20%;
  }
  .chart-labels-controls {
    font-size: 12px !important;
    margin-left: 0 !important;
  }
  .from-control-switch .PrivateSwitchBase-input {
    width: 50px !important;
  }
  .from-control-switch .css-jsexje-MuiSwitch-thumb {
    width: 18px;
    height: 18px;
  }

  .from-control-switch .css-1yjjitx-MuiSwitch-track {
    width: 80%;
    height: 80%;
  }

  .from-control-switch {
    width: 100px;
    margin: 5 !important;
  }
  .date-control {
    margin-left: 5px !important;
  }
  .date-control input {
    max-width: 80px;
    font-size: 10px;
  }
  .date-container {
    margin: 0 !important;
  }
  .control-btn {
    margin: 0 !important;
  }
  .control-btn button {
    min-width: 70px !important;
    font-size: 12px !important;
    width: 70px !important;
    margin: 0 5px !important;
    height: 30px !important;
  }
}

@media screen and (max-width: 912px) {
  .privacy-card {
    width: 75%;
    height: 75vh;
    padding: 50px;
  }
}

@media screen and (max-width: 820px) {
  .img-tigo-privacy {
    width: 30%;
  }
}

@media screen and (max-width: 768px) {
  .privacy-card {
    width: 75%;
    height: 70vh;
    padding: 50px;
  }
  .img-tigo-privacy {
    width: 30%;
  }
}

@media screen and (max-width: 540px) {
  .privacy-card {
    width: 75%;
    height: 55vh;
    padding: 50px;
  }
  .img-tigo-privacy {
    width: 40%;
  }
}

@media screen and (max-width: 414px) {
  .privacy-card {
    width: 70%;
    height: 65vh;
    padding: 50px;
  }
  .img-tigo-privacy {
    width: 50%;
  }
}

@media screen and (max-width: 412px) {
  .img-tigo-privacy {
    width: 50%;
  }
}

@media screen and (max-width: 375px) {
  .privacy-card {
    width: 90%;
    padding: 5px;
    height: 70vh;
  }
}

.autocompletemaps {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  text-transform: none;
  color: rgba(0, 0, 0, 0.87);
  border: none;
  width: 100%;
}
.autocompletemaps:focus {
  outline: none;
}
.box-autocomplete {
  padding: 7px;
  margin-left: 22px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  margin-top: 10px;
  width: 94%;
}
